import Client from "./clients/AxiosClient";
const resource = "/partners";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },
  getInternal() {
    return Client.get(`${resource}/internal`);
  },
  create(partner) {
    return Client.post(`${resource}`, partner);
  },
  update(partner,id) {
    return Client.put(`${resource}/${id}`, partner);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  getById(id) {
    return Client.get(`${resource}/${id}`);
  },
};
