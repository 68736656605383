import Client from "./clients/AxiosClient";
const resource = "/questions";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },
  getById(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(data) {
    return Client.post(`${resource}`, data);
  },
  update(data) {
    return Client.put(`${resource}/${data._id}`, data);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  import(formData) {
    return Client.post(`${resource}/import`, formData);
  },
  importMaster(formData, masterId) {
    return Client.post(`tests/${masterId}${resource}/import`, formData);
  },
  upload(formData) {
    return Client.post(`${resource}/upload`, formData);
  },
  deleteMultiple(data) {
    return Client.post(`${resource}/delete`, { questions: data });
  }
};
