module.exports = {
  ADMIN_ROLE: "admin",
  ADMIN_USERNAME: "admin",
  ROOT: "root",
  DEFAULT_PARTNER: "CATCURI",
  DEFAULT_WAREHOUSE: "CATCURI",
  DEFAULT_ROLE: "student",
  DEFAULT_PASSWORD: "123456",
  DEFAULT_PASSWORD_1: "Abc@123",
  DEFAULT_LIMIT: 10,
  DEFAULT_OFFSET: 0,
  DEFAULT_PAGE: 1,
  DEFAULT_TEST_TIME: 15,
  MAX_LIMIT: 100,

  DUPLICATE_MSG: " trùng dữ liệu. ",
  MISSING_MSG: " thiếu dữ liệu. ",
  NOT_EXIST_MSG: " không tồn tại. ",
  EXIST_MSG: " đã tồn tại. ",
  INVALID_MSG: " không hợp lệ. ",
  MIN_ANSWER: 2,
  MAX_ANSWER: 10,
  UPLOAD_IMPORT_FOLDER: "import",
  CORRECT_ANSWER_SIGNAL: "***",
  REQUIRE_SIGNAL: "(*)",
  JOIN_WORD: ", ",
  IMPORT_SHEET_QUESTION: "QUESTIONS",
  IMPORT_SHEET_USER: "USERS",
  IMPORT_SHEET_USER_ATTENDANCE: "USER_ATTENDANCE",
  FILE_VERSION_LENGTH: 5,
  FOLDER_TEMP: "temp",
  FOLDER_CONTENT: "content",

  PARAM_ID: "id",
  PARAM_USER_ID: "userId",
  PARAM_COURSE_ID: "courseId",
  PARAM_CHAPTER_ID: "chapterId",
  PARAM_TEST_ID: "testId",
  PARAM_LESSON_ID: "lessonId",
  PARAM_USER_TEST_ID: "userTestId",
  PARAM_WORKSHOP_ID: "workshopId",
  PARAM_PARTNER_ID: "partner_id",
  PARAM_PROMOTION_ID: "promotionId",

  GMT_TO_LOCAL_TIME: 7 * 3600000,
  DATE_FORMAT: "YYYY/MM/DD",
  HIDDEN_PHONE: "*****",
  UNVERIFIED: "unverified",

  FIRST_DATE_IN_MONTH: 1,
  MONTHS_START_QUARTER: [0, 3, 6, 9],
  MONTH_START_YEAR: 0,
  MONTH_IN_QUARTER: 3,
  MONTN_IN_YEAR: 12,
  ONE_MONTH: 1,
  ONE_YEAR: 1,
  PLATFORM: "WEB"
};
