import Client from "./clients/AxiosClient";
const resource = "/users";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },
  getInternal(params) {
    return Client.get(`${resource}/internal`, { params: params });
  },
  getById(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(user) {
    return Client.post(`${resource}`, user);
  },
  update(user, id) {
    return Client.put(`${resource}/${id}`, user);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  import(formData) {
    return Client.post(`${resource}/import`, formData);
  },
  getCourseInprogress(id, params) {
    return Client.get(`${resource}/${id}/courses`, {
      params: params
    });
  },
  getPointUser(id) {
    return Client.get(`${resource}/${id}/point`);
  },
  getPointUsed(id) {
    return Client.get(`${resource}/${id}/point/trans`);
  },
  getPointCoursesPromotions(params) {
    return Client.get(
      `${resource}/${params.user}/courses/${params.course}/promotions/total-rewards`
    );
  },
  resetPassword(user, id) {
    return Client.put(`${resource}/${id}/reset-password`, user);
  }
};
